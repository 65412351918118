import React from 'react'
import { Link } from 'react-router-dom';
import formatPrice from '../../../assets/functions/formatPrice';
import '../scss/itemListContainer/item.scss'
import Button from '../shared/Button';

const Item = ({ details }) => {

   const { id, image, name, newPrice, oldPrice } = details;

   return (
      <div className='main_card'>
         <Link to={`/item/${id}`} state={{ details }}>
            <div className='card_1'>
               <h3>{name}</h3>
               <img src={image} alt={name} width="300" />
               <p className="product_code">{id}</p>
               <div className="prices">
                  <p className="new_price">{formatPrice(newPrice)}</p>
                  <span className="old_price">{formatPrice(oldPrice)}</span>
               </div>
               <Button text='Ver detalles' />
            </div>
         </Link>
      </div>
   )
}

export default Item