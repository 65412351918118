import isEmpty from 'is-empty';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import formatPrice from '../../assets/functions/formatPrice';
import { CartContext } from '../../context/CartContext';
import Button from '../components/shared/Button';
import CartPageController from '../controllers/CartPageController';
import './scss/cartPage.scss';

const CartPage = () => {
  const { products, totalPrice, clear, removeItem } = useContext(CartContext);

  const [data, setData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    repeatEmail: ''
  })
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({
    text: '',
    status: false
  })

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    })
    if (!isEmpty(message.text)) {
      if (name === 'email') validateEmail(data.repeatEmail, value, true);
      if (name === 'repeatEmail') validateEmail(data.email, value, true);
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const status = validateEmail(data.email, data.repeatEmail);
    if (status) {
      CartPageController.saveOrder({
        setLoading,
        setMessage,
        setData,
        clear,
        order: {
          buyer: {
            name: data.name,
            surname: data.surname,
            phone: data.phone,
            email: data.email
          },
          items: products,
          date: new Date().toLocaleString(),
          total: totalPrice(),
          estado: 'generada'
        }
      })
    }
  }

  const validateEmail = (email1, email2, type = false) => {
    if (email1 !== email2) {
      setMessage({
        text: 'Los correos no coinciden',
        status: false
      })
      return false
    }
    else {
      if (type) {
        setMessage({
          text: 'Los correos coinciden',
          status: true
        })
      }
      return true
    }
  }

  const validateInputs = () => {
    if (!isEmpty(data.name) && !isEmpty(data.surname) && !isEmpty(data.phone) && !isEmpty(data.email) && !isEmpty(data.repeatEmail)) return false;
    return true;
  }

  const clickMe = () => {
    const elmnt = document.getElementById("root");
    elmnt.scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    clickMe()
  }, [])


  return (
    <main className='cart_container'>
      <div className='cart_space'>
        {isEmpty(products) ? <div className='no_items'>
          <p>No hay productos en el carrito :(</p>
          <Link to='/categories/accessories' className='btn-dark'>Navegar a un listado de productos</Link>
        </div> :
          <>
            <div className='list_item'>
              <h1>Listado de compras</h1>
              <div className="container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.map(({ image, name, quantity, newPrice, id }, key) =>
                        <tr key={key}>
                          <td>
                            <img src={image} alt={name} width='70' />
                            <p>{name}</p>
                          </td>
                          <td><span>{quantity}</span></td>
                          <td><span>{formatPrice(newPrice)}</span></td>
                          <td><span>{formatPrice(quantity * newPrice)}</span></td>
                          <td><span><i className="fa-sharp fa-solid fa-trash red" title='Eliminar producto' onClick={() => removeItem(id)}></i></span></td>
                        </tr>
                      )
                    }
                    <tr>
                      <td />
                      <td />
                      <td className='total'>Total:</td>
                      <td className='total'>{formatPrice(totalPrice())}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='clear_all'>
                <Button text='Eliminar carrito' onClick={clear} />
              </div>
              <p>Verifique y complete los campos solicitados para finalizar la compra. </p>
            </div>
            <div>
              <form onSubmit={onSubmit}>
                <div className="mb-3 ">
                  <label className="form-label text-left">Nombres*</label>
                  <input type="text" name='name' value={data.name} className="form-control form-control-lg" onChange={onChange} required />
                </div>
                <div className="mb-3 ">
                  <label className="form-label text-left">Apellidos*</label>
                  <input type="text" name='surname' value={data.surname} className="form-control form-control-lg" onChange={onChange} required />
                </div>
                <div className="mb-3">
                  <label className="form-label">Número telefónico*</label>
                  <input type="text" name='phone' value={data.phone} className="form-control form-control-lg" onChange={onChange} required />
                </div>
                <div className="mb-3 ">
                  <label className="form-label text-left">Correo electrónico*</label>
                  <input type="email" name='email' value={data.email} className="form-control form-control-lg" onChange={onChange} required />
                </div>
                <div className="mb-3 ">
                  <label className="form-label text-left">Repita correo electrónico*</label>
                  <input type="email" name='repeatEmail' value={data.repeatEmail} className="form-control form-control-lg" onChange={onChange} required />
                </div>
                {!isEmpty(message.text) &&
                  < p className={`message ${message.status ? 'success' : 'error'}`}>{message.text}</p>
                }
                <Button type='submit' text={loading ? 'Enviando...' : 'Realizar compra'} disabled={validateInputs()} />
              </form>
            </div>
          </>
        }
      </div>
    </main >
  )
}

export default CartPage