import isEmpty from 'is-empty'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import ItemDetail from '../components/ItemDetailContainer/ItemDetail'
import Loader from '../components/shared/Loader'
import ProductPageController from '../controllers/ProductPageController'
import './scss/productPage.scss'

const ProductPage = () => {
   const [product, setProduct] = useState({})
   const [loading, setLoading] = useState(true)

   const location = useLocation();
   const { id } = useParams();

   const data = location.state?.details;

   const clickMe = () => {
      const elmnt = document.getElementById("root");
      elmnt.scrollIntoView({
         behavior: 'smooth'
      });
   }

   useEffect(() => {
      clickMe()
      if (!isEmpty(data)) {
         setProduct(data)
         setLoading(false)
      } else {
         ProductPageController.getProductInfoByName({
            productId: id,
            setProduct,
            setLoading
         })
      }
   }, [data, id])

   return (
      <main className="main_product">
         <div className="page_space">
            {!loading ?
               (!isEmpty(product) ?
                  <ItemDetail product={product} /> :
                  <div className='no_items'>
                     <p>El producto no existe</p>
                     <Link to='/categories/accessories' className='btn-dark'>Navegar a un listado de productos</Link>
                  </div>
               ) :
               <Loader text='Buscando producto...' />
            }
         </div>
      </main>
   )
}

export default ProductPage