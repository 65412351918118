import React, { useEffect, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ItemListContainer from '../components/ItemListContainer/ItemListContainer'
import CategoryPageController from '../controllers/CategoryPageController'
import Loader from '../components/shared/Loader'
import './scss/categoryPage.scss'
import isEmpty from 'is-empty'

const CategoryPage = () => {

   const navigate = useNavigate();
   const { categoryId } = useParams();

   const [categoryInfo, setCategoryInfo] = useState(null);
   const [loadingProducts, setLoadingProducts] = useState(true);
   const [productList, setProductList] = useState([]);

   const handleCategoryPage = useCallback(async () => {
      const categoryInfo = await CategoryPageController.getCategoryInfoById({
         categoryId: categoryId,
         setCategoryInfo
      })
      if (!categoryInfo) return navigate('/');
      CategoryPageController.getProducsByCategoryId({
         categoryId: categoryId,
         setLoadingProducts,
         setProductList
      })
   }, [categoryId, navigate])

   const clickMe = () => {
      const elmnt = document.getElementById("root");
      if (!isEmpty(elmnt)) {
         elmnt.scrollIntoView({
            behavior: 'smooth'
         });
      }
   }

   useEffect(() => {
      handleCategoryPage();
      clickMe()
   }, [handleCategoryPage])

   return (
      <main className="main_category_product">
         {categoryInfo !== null &&
            <div className="page_space">
               <section className={`banner ${categoryId}`} style={{ backgroundImage: `url(${categoryInfo.image})` }}>
                  <div className="banner_information">
                     <h1>{categoryInfo.name}</h1>
                     <p>{categoryInfo.description}</p>
                  </div>
               </section>
               {loadingProducts ?
                  <div className='main_loader_list'>
                     <Loader text='Cargando productos...' />
                  </div> :
                  !isEmpty(productList) ?
                     <ItemListContainer productList={productList} /> :
                     <div className='product_space'><h2 className='no_products'>Sin productos para esta categoría</h2></div>
               }
            </div>
         }
      </main>
   )
}

export default CategoryPage